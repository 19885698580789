@import './scss/style.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    height: 100vh;
}

#root{
    height: 100%;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
    /* add other font formats here, if necessary */
  }
  @font-face {
    font-family: 'SpaceMono';
    src: url('./assets/fonts/SpaceMono-Regular.ttf') format('truetype');
    /* add other font formats here, if necessary */
  }
  @font-face {
    font-family: 'NeueMachina';
    src: url('./assets/fonts/Neue\ Machina/NeueMachina-Regular.otf') format('truetype');
    /* add other font formats here, if necessary */
  }
  
  .poppins {
    font-family: 'Poppins';
  }
  .space-mono{
    font-family: 'SpaceMono';
  }
  .neue-machina{
    font-family: 'NeueMachina';
  }