@media (max-height: 725px) {
  .info-solid .nft .active-menu-nft #adress {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  .info-solid .nft .active-menu-nft #adress span {
    font-size: 14px;
    font-weight: 500;
  }

  .info-solid .nft .active-menu-nft #id {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  .info-solid .nft .active-menu-nft #id span {
    font-size: 14px;
    font-weight: 500;
  }

  .info-solid .nft .active-menu-nft #dimensions {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  .info-solid .nft .active-menu-nft #dimensions span {
    font-size: 14px;
    font-weight: 500;
  }

  .info-solid .nft .active-menu-nft #frame {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  .info-solid .nft .active-menu-nft #frame span {
    font-size: 14px;
    font-weight: 500;
  }
}

@media (min-height: 725px) {
  .info-solid .nft .active-menu-nft #adress {
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
  }

  .info-solid .nft .active-menu-nft #adress span {
    font-size: 17px;
    font-weight: 500;
  }

  .info-solid .nft .active-menu-nft #id {
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
  }

  .info-solid .nft .active-menu-nft #id span {
    font-size: 17px;
    font-weight: 500;
  }

  .info-solid .nft .active-menu-nft #dimensions {
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
  }

  .info-solid .nft .active-menu-nft #dimensions span {
    font-size: 17px;
    font-weight: 500;
  }

  .info-solid .nft .active-menu-nft #frame {
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
  }

  .info-solid .nft .active-menu-nft #frame span {
    font-size: 17px;
    font-weight: 500;
  }
}

.h-100 {
  height: 100%;
}

.link {
  z-index: 1;
  position: relative;
}

.certificate {
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 30px;
  display: flex;
}

.certificate-img {
  width: 100%;
  height: 35%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.certificate-img img {
  height: 100%;
  width: auto;
  border-radius: 5%;
}

.certificate-img #img-certificate {
  width: 80%;
  height: auto;
  border-radius: 0;
}

.info-solid {
  width: 100%;
  height: 65%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 35px;
  display: flex;
}

.info-solid .certificate-menu {
  width: 80%;
  height: 30px;
  border-radius: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 0 7px #00000026;
}

.info-solid .nft {
  width: 80%;
  height: max-content;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  display: flex;
}

.middlemenu {
  width: 100%;
  height: 37vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.info-solid .nft .active-menu-nft {
  width: 95%;
  height: 20vh;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  display: flex;
  box-shadow: 0 0 7px #00000026;
}

.info-solid .nft .active-menu-nft div {
  text-align: center;
  width: max-content;
}

.info-solid .nft .active-menu-nft h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
}

.active-menu-nft-data {
  flex-direction: column;
  align-items: center;
  gap: 15px;
  display: flex;
}

.info-solid .certificate-owner {
  width: 90%;
  height: 36px;
  background: #fff;
  border-radius: 20px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0 20px;
  display: flex;
  box-shadow: 0 0 7px #00000026;
}

.info-solid .certificate-owner h3 {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
}

.info-solid .certificate-owner p {
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
}

.hosting {
  width: max-content;
  background-color: #f7f7f7;
  border-radius: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  margin: 0;
  padding: 0 8px;
  font-size: 8px;
  font-weight: 500;
  line-height: 0;
  display: flex;
  box-shadow: 5.7px 3.8px 3.1px #00000006, 15.7px 10.4px 8.5px #00000009, 37.7px 25px 20.5px #0000000c, 125px 83px 68px #00000012;
}

.hosting span {
  padding-left: 2px;
  font-weight: 800;
}

.certificate-menu button {
  width: 50%;
  height: 30px;
  text-align: center;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
}

div .border {
  border: #000;
  border-radius: 20px;
  padding: 6px 25px;
  box-shadow: 0 0 7px #00000026;
}

.solid {
  width: 80%;
  height: 60%;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  display: flex;
}

.solid .certificate-owner {
  width: 90%;
  height: 30px;
  background: #fff;
  border-radius: 20px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0 25px;
  display: flex;
  box-shadow: 0 0 7px #00000026;
}

.active-link-solid {
  width: max-content;
  border-radius: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  display: flex;
  box-shadow: 0 0 7px #00000026;
}

.active-link-solid a {
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.active-link-solid .logo-opensea img {
  width: 90px;
}

.active-link-solid .logo-arrow svg {
  width: 20px;
  height: 20px;
}

.active-link-solid .spacing {
  height: 10px;
}

.button-see-certif {
  color: #000;
  background-color: #fff;
  border-radius: 25px;
  padding: 3px 15px;
  box-shadow: 0 0 7px #00000026;
}

.arrow-stories {
  width: 24px;
}

.story-container {
  width: 100%;
  height: 100vh;
  background-color: #f6f6f6;
  overflow: hidden;
}

.story-container .screen {
  width: 400vw;
  height: 80%;
  transition: transform .6s ease-in-out;
  display: flex;
}

.story-container .screen .littlescreen {
  width: 100vw;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 3% 25px;
  display: flex;
}

.story-container .screen .littlescreen h1 {
  padding-bottom: 3%;
  font-size: 47px;
}

.story-container .screen .littlescreen p {
  text-align: start;
  padding-top: 25px;
  font-size: 27px;
}

.story-container .screen .littlescreen .special {
  font-size: 17px;
}

.story-container .screen .littlescreen .italic {
  font-style: italic;
}

.story-container .screen .littlescreen .mint-animation {
  height: 100%;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.story-container .screen .littlescreen .mint-animation .mintinprogress {
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.story-container .screen .littlescreen .mint-animation .mintinprogress .slider-text {
  height: 30%;
  width: 80%;
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

.story-container .screen .littlescreen .mint-animation .mintinprogress .slider-text .slider-text-step {
  font-size: 16px;
  transition: opacity .5s ease-in-out, transform .5s ease-in-out;
  transform: translateY(-25px);
}

.story-container .screen .littlescreen .mint-animation .mintinprogress .slider-text .slider-text-step.active {
  opacity: 0;
  transform: translateY(0);
}

.story-container .screen .littlescreen .mint-animation .mintinprogress .slider-text .slider-text-step:last-child {
  transition-delay: .75s;
}

.story-container .screen .littlescreen .mint-animation .mintinprogress .successmodules {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.story-container .screen .littlescreen .mint-animation .mintinprogress h2 {
  text-align: center;
}

.story-container .screen .littlescreen .mint-animation .mintinprogress .div-delay-success {
  width: max-content;
}

.story-container .screen .littlescreen .mint-animation .mintinprogress .detailsbutton {
  width: 290px;
  color: #fff;
  background: #000c;
  border-radius: 14px;
  padding: 11px 10px;
  font-size: 19px;
  font-weight: 700;
}

.story-container .screen .h100 {
  height: 100vh;
}

.story-container .buttons {
  width: 100%;
  height: 20%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5% 10%;
  display: flex;
}

.story-container .buttons .button-container {
  width: 20%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.story-container .buttons .button-container span {
  width: 4px;
  height: 4px;
  background-color: #6d6d6d;
  border-radius: 100%;
}

.story-container .buttons .button-container .active {
  width: 3px;
  height: 1.5px;
  background-color: #1f1f1f;
  transition: transform .6s ease-in-out;
  transform: scale(4);
}

.story-container .buttons .button {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.story-container .buttons .button button {
  background-color: #000;
  border-radius: 100%;
  position: absolute;
}

.story-container .buttons .button .icon {
  background-color: #000;
  border-radius: 100%;
  padding: 12px;
  position: absolute;
}

.story-container .buttons-mintscreen {
  height: 20%;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.story-container .buttons-mintscreen .button-mintscreen {
  width: 290px;
  color: #fff;
  background: #000c;
  border-radius: 14px;
  padding: 11px 10px;
  font-size: 19px;
  font-weight: 700;
}

.story-container .displaynone {
  height: 10%;
  display: none;
}

.container-controller-stories {
  width: 45%;
  justify-content: flex-end;
  align-items: center;
  gap: 14px;
  display: flex;
}

.container-controller-stories .arrow-first-stories {
  width: 30px;
}

.container-controller-stories .arrow-first-stories.disabled {
  cursor: not-allowed;
  opacity: .5;
}

.letterspace {
  letter-spacing: .1px;
}

.loading-page {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.loading-page .circular-loading {
  margin-top: -20px;
  margin-bottom: 2%;
}

.loading-page h2 {
  text-align: center;
  margin-bottom: 7%;
  font-size: 24px;
}

.undefined-screen {
  width: 100vw;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5% 25px;
  display: flex;
}

.undefined-screen h1 {
  padding-bottom: 3%;
  font-size: 47px;
}

.undefined-screen p {
  text-align: start;
  padding-top: 25px;
  font-size: 27px;
}

.undefined-screen .special {
  font-size: 17px;
}

.undefined-screen .italic {
  font-style: italic;
}

.undefined-screen .link {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  font-size: 22px;
  display: flex;
}

.button-staticstories {
  width: 70%;
  height: 45px;
  color: #fff;
  background-color: #000;
  border-radius: 12px;
}

.buttons-staticscreen {
  width: 100vw;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10%;
  padding: 0% 25px;
  display: flex;
}

.trueholdersvg-validate svg {
  width: 20px;
  height: 20px;
  color: #17cf57;
}

.trueholdersvg-not-validate {
  width: 20px;
  height: 20px;
  color: #e40b0b;
}

@tailwind base;

@tailwind components;

@tailwind utilities;

body {
  height: 100vh;
}

#root {
  height: 100%;
}

@font-face {
  font-family: Poppins;
  src: url("Poppins-Regular.d5d72893.ttf") format("truetype");
}

@font-face {
  font-family: SpaceMono;
  src: url("SpaceMono-Regular.8833c03b.ttf") format("truetype");
}

@font-face {
  font-family: NeueMachina;
  src: url("NeueMachina-Regular.64f921ad.otf") format("truetype");
}

.poppins {
  font-family: Poppins;
}

.space-mono {
  font-family: SpaceMono;
}

.neue-machina {
  font-family: NeueMachina;
}

/*# sourceMappingURL=index.3a9969eb.css.map */
