/* Media query for screen heights below 600px */
@media (max-height: 725px) { 
  .info-solid .nft .active-menu-nft #adress {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
  .info-solid .nft .active-menu-nft #adress span {
    font-weight: 500;
    font-size: 14px;
  }
  .info-solid .nft .active-menu-nft #id {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
  .info-solid .nft .active-menu-nft #id span {
    font-weight: 500;
    font-size: 14px;
  }

  .info-solid .nft .active-menu-nft #dimensions{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
  .info-solid .nft .active-menu-nft #dimensions span {
    font-weight: 500;
    font-size: 14px;
  }
  .info-solid .nft .active-menu-nft #frame{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
  .info-solid .nft .active-menu-nft #frame span {
    font-weight: 500;
    font-size: 14px;
  }
}
@media (min-height : 725px) {
  .info-solid .nft .active-menu-nft #adress {
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
  }
  .info-solid .nft .active-menu-nft #adress span {
    font-weight: 500;
    font-size: 17px;
  }
  .info-solid .nft .active-menu-nft #id {
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
  }
  .info-solid .nft .active-menu-nft #id span {
    font-weight: 500;
    font-size: 17px;
  }

  .info-solid .nft .active-menu-nft #dimensions{
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
  }
  .info-solid .nft .active-menu-nft #dimensions span {
    font-weight: 500;
    font-size: 17px;
  }
  .info-solid .nft .active-menu-nft #frame{
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
  }
  .info-solid .nft .active-menu-nft #frame span {
    font-weight: 500;
    font-size: 17px;
  }
}
.h-100{
  height: 100%;
}
.link{
  position: relative;
  z-index: 1;
}

.certificate {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px 30px 0px;
}
.certificate-img {
  width: 100%;
  height: 35%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.certificate-img img {
  border-radius: 5%;
  height: 100%;
  width: auto;
}
.certificate-img #img-certificate {
  border-radius: 0;
  width: 80%;
  height: auto;
}
.info-solid {
  width: 100%;
  height: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 35px;
}
.info-solid .certificate-menu {
  width: 80%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
}
.info-solid .nft {
  width: 80%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.middlemenu{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 37vh;
}
.info-solid .nft .active-menu-nft {
  width: 95%;
  height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}
.info-solid .nft .active-menu-nft div {
  text-align: center;
  width: max-content;
}
.info-solid .nft .active-menu-nft h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
}



.active-menu-nft-data{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
.info-solid .certificate-owner {
  width: 90%;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background: #fff;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 0px 20px;
}
.info-solid .certificate-owner h3 {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
}
.info-solid .certificate-owner p {
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
}
.hosting {
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  margin: 0;
  padding: 0;
  line-height: 0px;
  box-shadow: 5.7px 3.8px 3.1px rgba(0, 0, 0, 0.024), 15.7px 10.4px 8.5px rgba(0, 0, 0, 0.035), 37.7px 25px 20.5px rgba(0, 0, 0, 0.046), 125px 83px 68px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  background-color: #f7f7f7;
  padding: 0px 8px;
  font-size: 8px;
  font-weight: 500;
}
.hosting span {
  padding-left: 2px;
  font-weight: 800;
}
.certificate-menu button {
  width: 50%;
  height: 30px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 20px;
}

div .border {
  border: #000;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 6px 25px;
}
.solid {
  width: 80%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.solid .certificate-owner {
  width: 90%;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background: #fff;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 0px 25px;
}

.active-link-solid {
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 5px 15px;
  a{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
}

.active-link-solid .logo-opensea img {
  width: 90px;
}
.active-link-solid .logo-arrow svg {
  width: 20px;
  height: 20px;
}
.active-link-solid .spacing {
  height: 10px;
}

.button-see-certif{
  background-color: #fff;
  padding: 3px 15px;
  border-radius: 25px;
  color: #000;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
}
  
.arrow-stories{
  width: 24px;
}

  // STORIES -----------------------------------------//
  .story-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #F6F6F6;

    .screen {
      display: flex;
      width: 400vw;
      height: 80%;
      transition: transform 0.6s ease-in-out;

      .littlescreen{
        width: 100vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 3% 25px;
        h1{
          font-size:47px;
          padding-bottom: 3%;
        }
        p{
          font-size: 27px;
          padding-top: 25px;
          text-align: start;
        }
        .special{
          font-size: 17px;
        }
        .italic{
          font-style: italic;
        }
        .mint-animation{
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          .mintinprogress{
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            .slider-text {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              height: 30%;
              width: 80%;
              .slider-text-step {
                font-size: 16px;
                transform: translateY(-25px);
                transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
                &.active {
                  opacity: 0;
                  transform: translateY(0);
                }
            
                &:last-child {
                  transition-delay: 0.75s;
                }
              }
            }
            .successmodules{
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
            h2{
              text-align: center;
            }
            .div-delay-success{
              width: max-content;
            }
            .detailsbutton{
              width: 290px;
              color: #fff;
              background: #000c;
              border-radius: 14px;
              padding: 11px 10px;
              font-size: 19px;
              font-weight: 700;
            }
          }
        }
      }
      .h100{
        height: 100vh;
      }
    }
    .buttons{
      width: 100%;
      height: 20%;
      padding: 5% 10%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .button-container{
        width: 20%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        span{
          width: 4px;
          height: 4px;
          background-color: #6D6D6D;
          border-radius: 100%;
        }
        .active {
          background-color: #1f1f1f;
          width: 3px;
          height: 1.5px;
          transition: transform 0.6s ease-in-out;
          transform: scale(400%);
        }
      }
      .button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        button{
          position: absolute;
          background-color: #000;
          border-radius: 100%;
        }
        .icon{
          position: absolute;
          background-color: #000;
          border-radius: 100%;
          padding: 12px;
        }
      }
    }
    .buttons-mintscreen{
      height: 20%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .button-mintscreen{
        width: 290px;
        font-size: 19px;
        font-weight: 700;
        color: #fff;
        background: rgba(0, 0, 0, 0.8);
        border-radius: 14px;
        padding: 11px 10px;
      } 
    }
    .displaynone{
      display: none;
      height: 10%;
    }
  }
  .container-controller-stories{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
    width: 45%;
    .arrow-first-stories{
      width: 30px;
    }
    .arrow-first-stories.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
    
  }

  .letterspace{
    letter-spacing: 0.1px;
  }
//--------------------------------------------------//

//Loading Page--------------------------------------//
.loading-page{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .circular-loading{
    margin-top: -20px;
    margin-bottom: 2%;
  }
  h2{
    font-size: 24px;
    margin-bottom: 7%;
    text-align: center;
  }
}
//--------------------------------------------------//


//UNDEFINED SCREEN ----------------------//
.undefined-screen{
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5% 25px;
  h1{
    font-size:47px;
    padding-bottom: 3%;
  }
  p{
    font-size: 27px;
    padding-top: 25px;
    text-align: start;
  }
  .special{
    font-size: 17px;
  }
  .italic{
    font-style: italic;
  }
  .link{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10%;
    font-size: 22px;
  }
}
//---------------------------------------//

//Static stories--------------------------------------//
.button-staticstories{
  width: 70%;
  height: 45px;
  background-color: #000;
  color: #fff;
  border-radius: 12px;
}
.buttons-staticscreen{
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10%;
  align-items: center;
  padding: 0% 25px;
}
//--------------------------------------------------//

//true holder//
.trueholdersvg-validate svg{
  width: 20px;
  height: 20px;
  color: #17cf57;
}
.trueholdersvg-not-validate{
  width: 20px;
  height: 20px;
  color: #e40b0b;
}
//-----------//